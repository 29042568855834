import GATSBY_COMPILED_MDX from "/Users/dani/code/d4n1b/shirty-landing/posts/landing-b2b.es.mdx";
import React from "react";
import {graphql} from "gatsby";
import {SEOHead} from "../components";
function LayoutMedium({data, children}) {
  const {frontmatter} = data.mdx;
  return React.createElement("div", {
    className: "flex flex-col min-h-screen overflow-hidden"
  }, React.createElement(SEOHead, {
    title: frontmatter.title,
    description: frontmatter.description
  }), children);
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(LayoutMedium, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        id
        title
        slug
        language
        createdAt(formatString: "MMM DD, YYYY")
        updatedAt(formatString: "MMM DD, YYYY")
        showUpdatedAt
      }
    }
  }
`;
